import {mapMutations, mapState} from "vuex";
import cartStore from "./cartStore";
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    store: cartStore,
    data: () => ({
        addToCartLoading: false, // This is for the "Add to cart" button loader
        order: null,
        storage: 'default'
    }),
    computed: {
        ...mapState(['cartLoading', 'cartSize', 'cartProductList', 'intent']),
        products() {
            return this.cartProductList?.relationships?.order_items_groups || null
        },
        products_count() {
            return this.products ? this.products.length : 0
        }
    },
    methods: {
        ...mapMutations(['setCartSize', 'setCartSizeLoading', 'setCartProductList', 'removeProductFromCart', 'setIntent']),
        updateInfo(openBar = false) {
            this.setCartSizeLoading(true);
            this.$axios.get('/api/cart').then((response) => {
                let response_data = response.data.data;
                let item_groups = response_data?.relationships?.order_items_groups;
                this.setCartProductList(response_data)
                this.setIntent(response.data.intent || {})

                if (openBar) {
                    let offcanvas_el = document.getElementById("cart-bar-right")
                    let offcanvas = new bootstrap.Offcanvas(offcanvas_el)
                    offcanvas.show()
                }

                this.setCartSize((item_groups || []).length)
            }).catch((error) => {
                console.warn(error);
            }).finally(() => {
                this.setCartSizeLoading(false);
            });
        },
        addToCart(courseSlug, quantity = 1) {
            if (this.intent?.data?.discount_data?.sku_id) {
                let productFind = this.products.find(product => product.sku_id === this.intent.data.discount_data.sku_id)

                if (productFind.sku.product.slug === courseSlug) {
                    // TODO: change message
                    this.$swal('Error..', 'You have already applied a discount to this course', 'error')
                    return
                }
            }

            if (!this.addToCartLoading) {
                this.addToCartLoading = true;
                this.$axios.post('/api/cart', {
                    data: {
                        course_slug: courseSlug,
                        quantity: quantity
                    }
                }).then(r => {
                    if (r.data.error) {
                        this.$swal('Error..', r.data.error, 'error')
                    } else {
                        this.updateInfo(true);
                    }
                }).finally(() => {
                    this.addToCartLoading = false;
                })
            }
        },
        removeItemFromList(productId) {
            this.removeProductFromCart(productId)
        },
        // setShoppingCartSize(x) {
        //     //     let response_data = response.data.data;
        //     //     let item_groups = response_data.relationships.orderItemsGroups;
        //         this.setCartSize(x)
        //     //     this.updateCartProductList()
        //
        // },
        // updateCartProductList(x) {
        //         this.order = response.data.data;
        //         this.storage = response.data.storage || 'default';
        // }
    }
}