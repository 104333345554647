import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cartLoading: false,
        cartSize: "...",
        cartProductList: {},
        intent: {}
    },
    mutations: {
        setCartSize(state, cartSize) {
            state.cartSize = cartSize;
        },
        setCartSizeLoading(state, cartSizeLoading) {
            state.cartSizeLoading = cartSizeLoading;
        },
        setCartProductList(state, productList) {
            state.cartProductList = productList
        },
        removeProductFromCart(state, productId) {
            const found = state.cartProductList.relationships.order_items_groups.findIndex(o => o.id === productId);
            if (found > -1) {
                state.cartProductList.relationships.order_items_groups.splice(found, 1)
                state.cartSize = `${parseInt(state.cartSize) - 1}`
            }
        },
        setIntent(state, intent) {
            state.intent = intent
        },
    }
})